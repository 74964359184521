<template>
    <div class="loginContainer" ref="loginContainer">
        <div class="loginFormBg">
            <div class="loginForm">
                <div class="loginTitle">
                    <h3>用户登录界面</h3>
                    <h5>LOGIN INTERFACE</h5>
                </div>
                <div class="loginData">
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" :popper-append-to-body="false">
                        <el-form-item prop="username">
                            <el-input
                                placeholder="请输入手机号"
                                v-model="form.username"
                            >
                                <i slot="prefix" class="el-input__icon username"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input
                                placeholder="请输入密码"
                                v-model="form.password"
                                show-password
                            >
                                <i slot="prefix" class="el-input__icon password"></i>
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="code">
                            <el-input
                                placeholder="请输入验证码"
                                v-model="form.code"
                                >
                                <i slot="prefix" class="el-input__icon code"></i>
                            </el-input>
                        </el-form-item>
                    </el-form>
                            
                    <div class="valid">
                        <div id="captcha"></div>   
                    </div>        

                    <button class="loginBtn" @click="login">登录</button>      
                </div>
            </div>
        </div>
        <div class="login-bottom">
            <span>版权所属: <a href="http://www.keystarirobot.com">广东科凯达智能机器人有限公司</a></span>
            <a href="https://beian.miit.gov.cn/">粤ICP备19069665号</a>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import {postVerificationCode} from '../../http/alert'
export default {
    name: 'alert',

    data() {
        return {
            // 表单信息
            form: {                    // 表单数据
                username: '',          // 测试数据: test, 654321, O3WMYS
                password: '',
                code: ''
            },
            rules: {                   // 表单校验规则
                username: [{ required: true, message: "手机号不能为空", trigger: "blur" }],
                password: [{ required: true, message: "密码不能为空", trigger: "blur" }],
                code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
            },
            isValid: false,            // 是否验证成功
        }
    },

    methods: {
        ...mapMutations(['updateWarnToken', 'updateWarnId']),
        /**
         * @Author KR0288
         * @Date 2022/05/16 17:38:23
         * @Description 发送验证码
         */
        login() {
            this.$refs['ruleForm'].validate((valid) => {
                console.log(valid);
                if(this.isValid && valid) {
                    postVerificationCode(this.form).then(res => {
                        if(res.code == '000000') {
                            this.updateWarnToken(res.data.token);
                            this.updateWarnId(res.data.id)
                            this.$router.push('/defect');   
                            this.$message({
                                type: 'success',
                                message: res.data.message
                            })
                        }else {
                            this.$message({
                                type: 'error',
                                message: res.data
                            })
                        }
                    }).catch(err => {
                        this.$message({
                            type: 'error',
                            message: err
                        })
                    })     
                }else {
                    this.$message({
                        type: 'error',
                        message: '请先完成拖动滑块验证或表单验证'
                    })
                }                
            })
        }

    },

    mounted() {
        let that = this;

        // 1、响应式样式
        let loginContainerNode = this.$refs.loginContainer;
        loginContainerNode.style.height = window.innerHeight + 'px';
        window.onresize = () => {
            loginContainerNode.style.height = window.innerHeight + 'px';
        }
        

        // 2、声明滑块
        let slider = new SliderTools({
            el: document.getElementById('captcha'),
        })
        slider.on('complete', function () {
            that.isValid = true;
        })
    }
}
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'loginTitle';
    src: url('../../assets/imgs/common/PangMenZhengDaoBiaoTiTi-1的副本.ttf');
}

@mixin flexOrigin {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

input::-webkit-placeholder {
    text-align: center !important;
}

@media (min-width: 1200px) {
    .loginContainer {
        position: relative;
        width: 100%;
        min-width: 1200px;
        min-height: 754px;
        margin: 0 auto;
        background: url('../../assets/imgs/common/背景.png') no-repeat center center;
        background-size: 104% 107.6%;

        .loginFormBg {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 6.5742rem;
            min-width: 1122px;
            height: 3.7422rem;
            background: url('../../assets/imgs/common/方框.png') no-repeat;
            background-size: 100% 100%;

            .loginForm {
                @include flexOrigin;
                justify-content: space-between;
                min-width: 400px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 2.1484rem;
                height: 2.3438rem;

                .loginTitle {
                    @include flexOrigin;
                    width: 100%;
                    min-width: 400px;
                    height: .4271rem;
                    font-family: 'loginTitle';

                    h3 {
                        margin: 0;
                        font-size: .1875rem;
                        line-height: .1458rem;
                        color: white;
                    }

                    h5 {
                        margin: 0;
                        font-size: .1146rem;
                        line-height: .0938rem;
                        color: white;
                        opacity: 0.3;
                        letter-spacing: .026rem;
                    }
                } 

                .loginData {
                    @include flexOrigin;
                    width: 100%;
                    min-width: 325px;
                    padding: 0 .25rem;

                    /deep/ .el-form {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        min-width: 325px;

                        .el-form-item {
                            width: 1.5742rem;
                            height: .2188rem;
                            margin-left: 0 !important;

                            .el-form-item__content {
                                width: 100%;
                                height: 100%;
                                margin-left: 0 !important;

                                .el-input {
                                    height: 100%;

                                    .el-input__prefix {
                                        width: .2188rem;
                                        height: .2188rem;
                                        left: 0 !important;
                                        .username {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/账号图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                        .password {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/密码图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                        .code {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/验证码图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                    }

                                    .el-input__inner {
                                        height: 100%;
                                        padding-left: .2188rem !important;
                                        border: solid 2px #1c4b73;
                                        background-color: transparent;
                                        color: white !important;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }

                    .valid {
                        width: 100%;
                        #captcha {
                            #slider {
                                width: 1.5742rem  !important;
                                height: .2188rem !important;
                                margin: 0 auto;
                            }                            
                        }
                    }

                    .loginBtn {
                        width: 1.5742rem;
                        height: .2188rem;
                        margin-top: .2344rem;
                        font-size: .1016rem;
                        background-image: linear-gradient(0deg, 
                        rgba(49, 120, 220, 0.7) 0%, 
                        rgba(81, 183, 249, 0.7) 100%), 
                        linear-gradient(
                            #51b7f9, 
                            #51b7f9);
                        background-blend-mode: normal, normal;
                        color: white;
                        letter-spacing: .0104rem;
                        border-radius: 5px;
                        border-width: 0;
                        cursor: pointer;
                    }
                }           
            }

        }

        .login-bottom {
            position: absolute;
            bottom: 15px;
            color: #ffff;
            left: 50%;
            transform: translateX(-50%);
            font-size: 14px;
            a {
                color: #ffff;
                text-decoration: none;
                margin-left: 8px;
            }
            a:hover {
                color: #73dadd;
            }
        }
    } 
}

@media (max-width: 1199.99px) {
    .loginContainer {
        position: relative;
        width: 100%;
        min-height: 667px;
        background: url('../../assets/imgs/common/mobile-bg.png') no-repeat center center;
        background-size: 100% 100%;

        .loginFormBg {
            padding-top: 2.6933rem;

            .loginForm {
                @include flexOrigin;
                width: 8.56rem;
                height: 11.8667rem;
                margin: 0 auto;

                .loginTitle {
                    @include flexOrigin;
                    font-family: 'loginTitle';
                    width: 100%;
                    height: 1.3867rem;

                    h3 {
                        // height: .6133rem;
                        font-size: .8rem;
                        color: white;
                        text-align: center;
                        letter-spacing: 3px;
                        margin: 0;
                    }

                    h5 {
                        // height: .32rem;
                        font-size: .4rem;
                        letter-spacing: 3px;
                        color: #ffffff;
                        opacity: 0.3;
                        margin: 0;
                        font-weight: normal;
                    }
                }

                .loginData {
                    height: 7.44rem;
                    width: 100%;
                    /deep/ .el-form {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        min-width: 325px; 

                        .el-form-item {
                            width: 8.5333rem;
                            height: 1.1733rem;
                            margin-left: 0 !important;

                            .el-form-item__content {
                                width: 100%;
                                height: 100%;
                                margin-left: 0 !important;

                                .el-input {
                                    height: 100%;

                                    .el-input__prefix {
                                        width: 1.1733rem;
                                        height: 1.1733rem;
                                        left: 0 !important;
                                        .username {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/账号图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                        .password {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/密码图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                        .code {
                                            display: block;
                                            width: 100%;
                                            height: 100%;
                                            background: url('../../assets/imgs/common/验证码图标.png') no-repeat center center;
                                            background-size: 50% 50%;
                                        }
                                    }

                                    .el-input__inner {
                                        height: 100%;
                                        padding-left: 1.1733rem !important;
                                        border: solid 2px #1c4b73;
                                        background-color: transparent;
                                        color: white !important;
                                        opacity: 0.6;
                                    }
                                }
                            }
                        }
                    }

                    .valid {
                        width: 100%;
                        #captcha {
                            #slider {
                                width: 8.5333rem !important;
                                height: 1.1733rem !important;
                                margin: 0 auto;
                            }                            
                        }
                    }

                    .loginBtn {
                        width: 8.5333rem;
                        height: 1.1733rem;
                        margin-top: 1.6rem;
                        font-size: .4rem;
                        letter-spacing: .0267rem;
                        background-image: linear-gradient(0deg, 
                        rgba(49, 120, 220, 0.7) 0%, 
                        rgba(81, 183, 249, 0.7) 100%), 
                        linear-gradient(
                            #51b7f9, 
                            #51b7f9);
                        background-blend-mode: normal, normal;
                        color: white;
                        border-radius: 5px;
                        border-width: 0;
                        cursor: pointer;
                    }
                }
            }
        }

        .login-bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            position: absolute;
            bottom: 15px;
            color: #ffff;
            left: 50%;
            width: 80%;
            transform: translateX(-50%);
            font-size: 14px;
            a {
                color: #ffff;
                text-decoration: none;
                margin-left: 8px;
            }
            a:hover {
                color: #73dadd;
            }
        }
    }
}

</style>