/*
 * @Author: hanson 1130845163@qq.com
 * @Date: 2022-05-16 17:32:51
 * @LastEditors: hanson 1130845163@qq.com
 * @LastEditTime: 2022-06-07 16:37:16
 * @FilePath: \2002181_OTLRWS_FRONTED_refactoring\src\http\alert.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import qs from 'qs';
import axios from "./ajax.js"; 
import store from '../store/index'

/**
 * @Author KR0288
 * @Date 2022/05/16 17:34:05
 * @Description 发送手机号和验证码登录缺陷页面
 */
export const postVerificationCode = data => {
    return axios({
        url: '/eprdms/device/alarm/message/login',
        method: 'post',
        data
    })
}

/**
 * @Author KR0288
 * @Date 2022/05/17 13:15:52
 * @Description 获取缺陷信息
 */
export const getDefectInfo = data => {
    return axios({
        url: '/eprdms/device/alarm/message/get/' + store.state.warnToken,
        method: 'post',
        data
    })
}

/**
 * @Author KR0288
 * @Date 2022/05/27 14:52:06
 * @Description 下载原图
 */
export const getOriginImage = data => {
    return axios({
        url: '/eprdms/device/alarm/message/download/photo/' + store.state.warnToken,
        method: 'post',
        data,
        type: 'downloadWarning'
    })
}

/**
 * @Author KR0288
 * @Date 2022/05/27 14:52:13
 * @Description 下载报告
 */
 export const getReport = data => {
    return axios({
        url: '/eprdms/device/alarm/message/download/report/' + store.state.warnToken,
        method: 'post',
        data,
        type: 'downloadWarning'
    })
}

/**
 * @Author KR0288
 * @Date 2022/05/27 15:34:27
 * @Description 用户确认告警
 */
export const confirmAlarm = data => {
    return axios({
        url: '/eprdms/device/alarm/message/confirm/alarm/' + store.state.warnToken,
        method: 'post',
        data       
    })
}